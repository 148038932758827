import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "複数プロダクト横断したプロジェクトをどうやって成功させたのかメンバーに聞いてみました",
  "date": "2024-02-29T14:22:09.000Z",
  "slug": "entry/2024/02/29/232209",
  "tags": [],
  "hero": "./2024_02_29.png",
  "heroAlt": "複数プロダクト横断したプロジェクトをどうやって成功させたのかメンバーに聞いてみました"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの古川です。`}</p>
    <p>{`今回は CLINICS / Pharms 同時予約プロジェクト(以下、同時予約 Pj)というプロダクト横断で様々な部署が関わった開発プロジェクトについて、尽力された皆さんに座談会形式でお話を伺いました。`}</p>
    <p>{`メドレーの医療プラットフォーム(以下、医療 PF)でどのようにこうした横断プロジェクトを完遂したのかの様子を皆さんに知ってもらえればと思います。`}</p>
    <h1>{`対談メンバー紹介`}</h1>
    <p><strong parentName="p">{`── はじめに、皆さんの自己紹介をお願いします。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 所属は医療 PF のプロダクト開発室の Pharms 開発チームです。 役割としては調剤薬局向けのシステムの Pharms のプロダクトオーナーを担っています。`}</p>
    <p>{`経歴としては、2021 年の 1 月から Pharms のカスタマーサクセスとしてメドレーにジョインしまして、2023 年 1 月から開発チームに来ました。`}</p>
    <p>{`これまでは事業側の経験が長く、営業全般や事業開発をメインでやってきたキャリアです。`}</p>
    <p>{`このプロジェクトの PO としてリードする動きをしており、Pharms が担う調剤側のリードに加えて、全体の旗振りや調整等を主軸にやっていました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6d69f09e282a05af93fe48a7f8b7df28/eea4a/_dev202403_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABfVio3TFhH//EABsQAAIBBQAAAAAAAAAAAAAAAAECEQASMTJC/9oACAEBAAEFAkgKDY81yMLr/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQETFRcf/aAAgBAQAGPwJLRaxD7CP/xAAdEAABBAIDAAAAAAAAAAAAAAAAAREhQRAxYaHR/9oACAEBAAE/IWpqwiwomvON1+ScjpH/2gAMAwEAAgADAAAAEP8A3//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QSwh//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/ELWv/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUWHB8P/aAAgBAQABPxCxNAAXoiaqLFZF69yuEyJZBQC0IPOk8xnmN2/Fn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 005",
            "title": " dev202403 005",
            "src": "/static/6d69f09e282a05af93fe48a7f8b7df28/e5166/_dev202403_005.jpg",
            "srcSet": ["/static/6d69f09e282a05af93fe48a7f8b7df28/f93b5/_dev202403_005.jpg 300w", "/static/6d69f09e282a05af93fe48a7f8b7df28/b4294/_dev202403_005.jpg 600w", "/static/6d69f09e282a05af93fe48a7f8b7df28/e5166/_dev202403_005.jpg 1200w", "/static/6d69f09e282a05af93fe48a7f8b7df28/eea4a/_dev202403_005.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`江藤さん`}</em></p>
    <p><strong parentName="p">{`── 江藤さんがプロジェクトの中心となって推進したんですね。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 調剤ドメインの Pharms にとって事業インパクトがすごく大きい開発だったので、調剤ドメインが責任持ってやりましょう、という整理です。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: 自分の所属も江藤さんと同じになります。 経歴は、2021 年 7 月にエンジニアとして入社し、これまでずっと Pharms の開発をやってます。`}</p>
    <p>{`入社後はクライアント認証機能や法人担当者向けの本部機能などの機能開発を行ってきました。
昨年から Pharms のリードエンジニアとしてチーム全体を見つつ、Pharms の技術責任者として江藤さんと両輪で動くような形でプロジェクトの推進に関わっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a29f36e9abaa6a14a6a82224d39c34ad/eea4a/_dev202403_014.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGorFQgElf/xAAYEAEBAAMAAAAAAAAAAAAAAAABAAIDMf/aAAgBAQABBQISMQ2LHZv/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAwEBPwGH/8QAFREBAQAAAAAAAAAAAAAAAAAAEDH/2gAIAQIBAT8Bp//EABYQAAMAAAAAAAAAAAAAAAAAAAASIP/aAAgBAQAGPwIaf//EABoQAAIDAQEAAAAAAAAAAAAAAAABITFREXH/2gAIAQEAAT8h0IauNn0kKh0ix//aAAwDAQACAAMAAAAQJM//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBj/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EJTX/8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFRcaH/2gAIAQEAAT8QVm3sOIBt2XlcrzHTmNz7I28//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 014",
            "title": " dev202403 014",
            "src": "/static/a29f36e9abaa6a14a6a82224d39c34ad/e5166/_dev202403_014.jpg",
            "srcSet": ["/static/a29f36e9abaa6a14a6a82224d39c34ad/f93b5/_dev202403_014.jpg 300w", "/static/a29f36e9abaa6a14a6a82224d39c34ad/b4294/_dev202403_014.jpg 600w", "/static/a29f36e9abaa6a14a6a82224d39c34ad/e5166/_dev202403_014.jpg 1200w", "/static/a29f36e9abaa6a14a6a82224d39c34ad/eea4a/_dev202403_014.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`小田さん`}</em></p>
    <p><strong parentName="p">{`有馬`}</strong>{`: 医療 PF プロダクト開発室、患者統合基盤チームに所属しています。患者統合基盤とは、CLINICS（患者アプリ）というアプリのバックエンドシステムであり、診療所や歯科医院、薬局向けのシステムとの連携基盤としても機能しているプロダクトになります。`}</p>
    <p>{`経歴は、このメンバーの中では一番古く 2018 年 3 月に入社して、当初は CLINICS カルテの機能拡張の開発に参加し、PKI 基盤や CLINICS エージェントを始めとした他社システムとの連携基盤の開発を行っていました。2020 年くらいから患者統合基盤の立ち上げに参加して、現在に至ります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28c0b8ba8b74c0c8ecdb27e0698f3755/eea4a/_dev202403_009.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWZhUJly/wD/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAEAxITMv/aAAgBAQABBQIsqrjKo3SW/NEDWf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAEDBQEAAAAAAAAAAAAAAAABAhIQMTJhgpH/2gAIAQEABj8CdKxDwxVTocu6f//EAB0QAAICAQUAAAAAAAAAAAAAAAERACEQMUFhcfD/2gAIAQEAAT8hOJplA5gGNFwg65Snv1KGD3Y//9oADAMBAAIAAwAAABC0P//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QHjAZf//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCUbP/EABwQAAIDAQADAAAAAAAAAAAAAAERACExQWGBof/aAAgBAQABPxAS0z/LCQmQwuAFbYz3HigcIPsIrfcYhQFLSxwGhP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 009",
            "title": " dev202403 009",
            "src": "/static/28c0b8ba8b74c0c8ecdb27e0698f3755/e5166/_dev202403_009.jpg",
            "srcSet": ["/static/28c0b8ba8b74c0c8ecdb27e0698f3755/f93b5/_dev202403_009.jpg 300w", "/static/28c0b8ba8b74c0c8ecdb27e0698f3755/b4294/_dev202403_009.jpg 600w", "/static/28c0b8ba8b74c0c8ecdb27e0698f3755/e5166/_dev202403_009.jpg 1200w", "/static/28c0b8ba8b74c0c8ecdb27e0698f3755/eea4a/_dev202403_009.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`有馬さん`}</em></p>
    <p><strong parentName="p">{`酒井`}</strong>{`: 入社は 2019 年 8 月なので、 5 年目となります。 デザイナーとして入社後 1 年間はジョブメドレーに在籍していました。その後オンライン診療が伸びてきた時期になり、CLINICS 側のデザイナーの人数が足りなくなったことがありまして、そこからは電子カルテを含めた CLINICS のデザイン全般を担当していました。去年から電子カルテ以外の業務領域の PdM として活動しています。軸足はデザイナーなのですがプロダクトのマネジメントみたいなこともし始めてます。`}</p>
    <p>{`このプロジェクトにおいては、さっき江藤さんがおっしゃった通り、Pharms の価値向上が中心のプロジェクトなのでプロジェクトの一番大きいリードは江藤さんになるかと思うんですけど、CLINICS などの医科側のプロジェクトリードと言う立ち位置が僕になるかなと思います。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40f952b3cde627f7d3b874011abc0b18/eea4a/_dev202403_012.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAWDNpMrMJUf/xAAbEAACAwADAAAAAAAAAAAAAAACAwABEgQyQv/aAAgBAQABBQJl5FRDUWwc8np7n//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AVn/xAAYEAEAAwEAAAAAAAAAAAAAAAABAAIQcf/aAAgBAQAGPwJY4dl8/8QAGxABAQABBQAAAAAAAAAAAAAAAQBBEBEhMbH/2gAIAQEAAT8hJDqEBZnOkHhK7vN//9oADAMBAAIAAwAAABA8P//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAwEBPxAJ2//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QkJYf/8QAHBABAQACAgMAAAAAAAAAAAAAAREAIUFRYYGh/9oACAEBAAE/EKdgNzBWeG9wlcBFUV17xpNSkfOnGwrqfmCIRvhz/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 012",
            "title": " dev202403 012",
            "src": "/static/40f952b3cde627f7d3b874011abc0b18/e5166/_dev202403_012.jpg",
            "srcSet": ["/static/40f952b3cde627f7d3b874011abc0b18/f93b5/_dev202403_012.jpg 300w", "/static/40f952b3cde627f7d3b874011abc0b18/b4294/_dev202403_012.jpg 600w", "/static/40f952b3cde627f7d3b874011abc0b18/e5166/_dev202403_012.jpg 1200w", "/static/40f952b3cde627f7d3b874011abc0b18/eea4a/_dev202403_012.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`酒井さん`}</em></p>
    <p><strong parentName="p">{`小島`}</strong>{`: 医療 PF プロダクト開発室の QA グループに所属しています。QA グループのマネージャである`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2022/05/31/203823"
      }}>{`米山`}</a>{`さんのご紹介で 2022 年 4 月にメドレー３人目の QA エンジニアとして入社しました。主担当は CLINICS の予約機能を中心とした周辺領域です。ここまで酒井さんと一緒に働かせて頂く機会が多く、直近では予約方法を拡張する「リクエスト予約」機能の QA を担当しました。`}</p>
    <p>{`同時予約に関しては、主に医科側の部分の QA から始まり、調剤側の QA も必要ということになり、最終的には全体の QA を担当しました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3b5bd0f28c7affe665e30ac27968509/eea4a/_dev202403_016.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG3gZJlUJD/xAAcEAACAQUBAAAAAAAAAAAAAAABAgADBBESMTP/2gAIAQEAAQUCdgq0yDHJ2ufEdzP/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAIBEBExUYH/2gAIAQEABj8CuNsxJ0en/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFBcaH/2gAIAQEAAT8hUNsz0Q8A61ah6MvhkkGYScrv/9oADAMBAAIAAwAAABAEz//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxAUmrv/xAAXEQADAQAAAAAAAAAAAAAAAAAAASER/9oACAECAQE/EHKYj//EABwQAQACAwADAAAAAAAAAAAAAAEAESExUUFhsf/aAAgBAQABPxAaAY4ldTFI0M/Yyzzxi3fIggWkH2ZgKwo012FVTW1n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 016",
            "title": " dev202403 016",
            "src": "/static/e3b5bd0f28c7affe665e30ac27968509/e5166/_dev202403_016.jpg",
            "srcSet": ["/static/e3b5bd0f28c7affe665e30ac27968509/f93b5/_dev202403_016.jpg 300w", "/static/e3b5bd0f28c7affe665e30ac27968509/b4294/_dev202403_016.jpg 600w", "/static/e3b5bd0f28c7affe665e30ac27968509/e5166/_dev202403_016.jpg 1200w", "/static/e3b5bd0f28c7affe665e30ac27968509/eea4a/_dev202403_016.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`小島さん`}</em></p>
    <h1>{`同時予約の機能や解決した課題`}</h1>
    <p><strong parentName="p">{`── ありがとうございます。次に、今回の同時予約についてどういった機能で、どういった課題を解決するべきかをお話しいただきたいです。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 機能としては、`}<strong parentName="p">{`CLINICS（患者アプリ）からオンライン診療を予約する際に、薬の受け取り先として薬局を事前指定できる機能`}</strong>{`になっています。
この機能に関係するドメインは患者・医療機関・調剤薬局の３つです。`}</p>
    <p>{`患者と医療機関のメリットとしては、薬の処方までの手間が減ることです。`}</p>
    <p>{`オンライン診療では、患者が医療機関に直接訪問するわけではないので、薬を直接薬局に行って受け取るのか、薬局から配送を希望するのかといった薬の受け取り方を、医療機関と患者さんで口頭で話して決めてもらっており、確認に手間が発生していました。患者さんは口頭で話した内容に沿って、オンライン診療終了後に、薬局への予約を取っていました。`}</p>
    <p>{`同時予約機能が実装されたことにより、診察の予約時にどの薬局でどういう受け取り方をしますという部分を患者さんが事前指定できるようになったので、`}<strong parentName="p">{`医療機関は事務的な話に時間をかけずに診察に集中でき、また患者さんから医師に口頭で追加説明しなくても希望通りに薬をもらえる、というフローに変更されたことが医療機関と患者のメリット`}</strong>{`だと思ってます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/14c0054ea4d871c3a05b0951ec1829e8/21b4d/mag_Pharms_1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsSAAALEgHS3X78AAABqElEQVQoz01RyXLTUBDU/38FB05c+ISkCAGDK14wKe+2ZHmRtUXS0/LmzUbZIgVzmpqepafb67ouz3PnnKoGYfA72RXYbPNwHC1KMCqaZikR5kk6Oa9SNGEZTa/LyCSi4okqKjvGvClG00n144WP54LaytbErKqiYlw9W8zj4ZgXayOQtQUyqarHwo7QEhDTMYtW+9e3KmvIqgjQjY4lUNVLkSyDeZZFBjsR7iGvQ9uiraBWVT/0N8Ov2eK1tLWoOEYWztriNpzGi/EgGQ3LtuT3vd46D1aZP4vXjPT85Sn9/hM2W1VlYRYurJnF6w7t0+Bb8DyA6S9lZpV/l9+6yrgmMfnm4pPovDhGdeoIHaOIdGiJyVQVIAU23xcnx2gR+p+lb/KLs1/Holp11TY71ND0qvQo3xJqoT0W0dWk/wvmHGNt2+vL+HI4+KeQVZGp5wbkWPU6mcW+vwsDJ4zCvYqeqFiCjqBoq9OHj8fFcrVZl4+PcDrJ3ShmapWiT5/Pw9Eq8JOHB9js7h6Kp+8hqgYA0CEA7Q9SVn+r92gcACERYhBKlt8h+QOkqzZVoZlC8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "mag Pharms 1",
            "title": "mag Pharms 1",
            "src": "/static/14c0054ea4d871c3a05b0951ec1829e8/c1b63/mag_Pharms_1.png",
            "srcSet": ["/static/14c0054ea4d871c3a05b0951ec1829e8/5a46d/mag_Pharms_1.png 300w", "/static/14c0054ea4d871c3a05b0951ec1829e8/0a47e/mag_Pharms_1.png 600w", "/static/14c0054ea4d871c3a05b0951ec1829e8/c1b63/mag_Pharms_1.png 1200w", "/static/14c0054ea4d871c3a05b0951ec1829e8/21b4d/mag_Pharms_1.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`同時予約機能のリリース前後でのフローの違い`}</em></p>
    <p>{`もう一つのドメインである調剤薬局については、前述のように診察の予約をする時に患者さんがメドレーのサービスである Pharms を使っている薬局から事前指定をできますので、`}<strong parentName="p">{`オンライン診療後に発行された処方箋の流入が増加します`}</strong>{`。`}</p>
    <p>{`調剤薬局の売り上げは処方箋の枚数 × 単価で大部分が決まりますので、この枚数を増やせるという観点では薬局にとってインパクトのある機能だと思っています。`}</p>
    <p><strong parentName="p">{`── 患者の体験も本当に良くなるし、患者と医療機関の無駄なコミュニケーションも減り、かつ薬局に対しても処方箋枚数が増えて、導線も楽に使えるようになっているというのが、今回のプロジェクトの肝になっている感じですね。これらを踏まえ、プロダクト間を横断して開発しないといけないというのが大変そうです。`}</strong></p>
    <h1>{`開発プロジェクトについて`}</h1>
    <p><strong parentName="p">{`── どのようなチームがこの横断プロジェクトに関わっていたかを教えてください。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 開発側で言うと４つのドメインになってます。`}</p>
    <p>{`調剤、患者、医科、そして `}<strong parentName="p">{`患者統合基盤`}</strong>{`です。患者統合基盤とは医療 PF の各ドメインに存在する業務システムと患者アプリを繋ぐ根幹となるプロダクトです。`}</p>
    <p>{`ただ今回の機能は医療機関のオペレーションも変わるため、開発側でのみ完結するプロジェクトではないんですね。`}</p>
    <p><strong parentName="p">{`いかに現場の運用に合わせつつ、体験を改善できるか？という視点で考えると、各事業側のカスタマーサクセスチームが重要になります`}</strong>{`。特に医科と調剤のカスタマーサクセスチームとは、密に壁打ちをしながら開発を進めていました。`}</p>
    <p><strong parentName="p">{`── 今回のプロジェクトは特に事業部との関係性が重要な鍵だったんですね。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: そうですね。特に医療現場は業務が逼迫している場面も多いですので、例えば「ワンクリック増えます」「ここでページ遷移が増えます」といったことでも業務への影響が非常に大きくなります。`}</p>
    <p>{`またオペレーショナルに現場を回している箇所も多く、プロダクト都合で勝手にこの方が良いよね、合理的だよね、と決めてプロダクトを作ってしまうと今までの現場オペレーションが崩れてしまい混乱を招くことがあります。`}</p>
    <p><strong parentName="p">{`現場の習慣を理解し、我々が目指す姿と調整しながら最適解を出す`}</strong>{`のが、必須になってくる業界・業態だと思うので、その調整は事業部ともかなり気を遣ってやっていました。`}</p>
    <p><strong parentName="p">{`── 同時予約 Pj は、開発側がやろうということで始まったプロジェクトなんでしょうか?`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: はい。これはメドレーのどのプロダクトもそうなのですが、事業責任者とプロダクト責任者がそれぞれおり、事業と開発の両観点から議論し、事業方針を決めていく、という体制をとっています。`}</p>
    <p>{`Pharms を正しく成長させていくという観点で見ると、現フェーズでは処方箋をしっかりと調剤薬局に届けることが至上命題でした。サービスのあるべき姿とは？という観点で事業と開発両輪でやりましょうという形で決まったものになります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f63c18e55b784f34d7b402805cbba814/eea4a/_dev202403_003.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFJqWzF8yK//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAMgMREv/aAAgBAQABBQLCeA4DKp2sFEp//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAECERAhMZH/2gAIAQEABj8ClfhFvr2K8o//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhcaH/2gAIAQEAAT8hFr2s4gZgd+ywYUhHieGf/9oADAMBAAIAAwAAABAML//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EBn/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxCH/8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFBcRCx0f/aAAgBAQABPxAzA2U51FO4TvC70EIinzFJFBLt4NSt1xu5x9Z//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 003",
            "title": " dev202403 003",
            "src": "/static/f63c18e55b784f34d7b402805cbba814/e5166/_dev202403_003.jpg",
            "srcSet": ["/static/f63c18e55b784f34d7b402805cbba814/f93b5/_dev202403_003.jpg 300w", "/static/f63c18e55b784f34d7b402805cbba814/b4294/_dev202403_003.jpg 600w", "/static/f63c18e55b784f34d7b402805cbba814/e5166/_dev202403_003.jpg 1200w", "/static/f63c18e55b784f34d7b402805cbba814/eea4a/_dev202403_003.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`横断プロジェクトで工夫をしたポイント`}</h1>
    <p><strong parentName="p">{`── 今回、横断で色々な開発プロダクトが関係してきますが、工夫した点とか気をつけた点など、どんなものがありましたか。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 一番は各ドメインで追っている事業成果がかなり違っていましたので、その調整を時間をかけて擦り合わせした点です。`}</p>
    <p>{`例えば、調剤の観点では処方箋をちゃんと薬局に届けるというのが、すごく重要な事業インパクトの大きい項目である一方で、医療の観点で見た時に、もちろん手間を減らすのはすごい重要ではあるんですが、医療ドメインとしてその改善に調剤と同規模のメリットを見出してもらえるかと言うと当然そうではなかったりします。`}</p>
    <p>{`こうした部分で、ドメイン間でこのプロジェクトを達成した後に得られるメリットに差分が出てきます。メリットが大きい事業からしたら工数も大きく割けるのですが、メリットが弱い事業では同じだけ工数かけてやりましょう、という意思決定は難しいと思います。`}</p>
    <p>{`そこで`}<strong parentName="p">{`本 Pj を通じて各ドメインではどういう事業成果を生むのか、そのために何の KPI を追うのか、を明確にしました`}</strong>{`。事業的なメリットが多い調剤のために手伝ってくださいという形ではなく、各ドメインでどういうメリットを生んでいきますか？というところを揃えきるのが、すごく重要だったと思っています。`}</p>
    <p>{`これを怠ると、メリットの弱い事業にとってはお手伝いみたいなプロジェクトになります。単純にエンジニアリングという部分でも面白いものにならないですよね。ですので、関係する全員でプロジェクトを実施することでどういう結果を得るのか?をちゃんと各ドメイン間で揃えました。`}</p>
    <p><strong parentName="p">{`── なるほど。そうするとそうした擦り合わせは江藤さんと酒井さんがやっていたんでしょうか。`}</strong></p>
    <p><strong parentName="p">{`酒井`}</strong>{`: はい、僕のほうで行っていました。僕が初期のすり合わせ時に重視していたのは、スコープを適切に区切るという部分でした。調剤側の提供価値の最大化だけを追って初期から大きな機能追加を行うのではなく、変更による医科・患者・調剤へ与えるリスクや開発コストを抑えつつ、価値を最大化できる落とし所はどこか、という部分から刷り合わせしていきました。`}</p>
    <p>{`メドレーが面白いなって思うのが、プロダクト単独で動いていくわけではなくて、`}<strong parentName="p">{`ペイシェントジャーニー(患者体験)を中心としてプロタクト群が存在しているところ`}</strong>{`です。 患者体験をより良く構築するためにそれぞれのプロダクトが動いていけるので、お手伝いというニュアンスじゃなく、プロダクトを超えてここはよくしていきましょうと言える文化があるところがいい部分かなと思いますし、やりがいがある部分かなと思います。`}</p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 特に横断のプロジェクトだとこうした目的意識の統一に一番時間を使っても良いんじゃないかと考えています。ここが決まりさえすれば、後々迷った場合も目的に即した意思決定ができますし。ここを固めた後に各ドメインでの調整するという順番が大事だと思います。`}</p>
    <p><strong parentName="p">{`── 技術面で気をつけたポイントはどんなものがあったんでしょうか。`}</strong></p>
    <p><strong parentName="p">{`有馬`}</strong>{`: 同時予約機能としてやりたいことはありつつ、診療所側の業務フローや患者の導線、今あるシステムの制約などを踏まえ、どのような形に落とし込めば`}<strong parentName="p">{`診療所も薬局も患者にとっても価値のある機能となるか`}</strong>{`がポイントです。`}</p>
    <p>{`ですので、技術的な面と事業的な面を併せて考えながら、システム全体の要件を決めていくというのが最初に手がけた部分でした。`}</p>
    <p><strong parentName="p">{`── 機能を考える上で、全体を見据えた設計などをやっていくと思うんですが、今回はどこから着手されたんでしょうか?患者の導線だったり医療機関の業務フローなど把握した後に、取りかかった部分を聞きたいのですが。`}</strong></p>
    <p><strong parentName="p">{`有馬`}</strong>{`: 同時予約におけるシステム全体の要件を固めていくと同時に、全体のシーケンスとステートマシンを作っていきました。各システム間の連携フローと、それにより生み出される予約や処方箋といったエンティティの状態遷移を決めていき、この状態の時はこういったことはできないとか、そういった辻褄を合わせるところをしっかりやっていったという感じですかね。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{`: 今回は、特に`}<strong parentName="p">{`プロダクト横断の共通基盤として、各プロダクトでそれぞれ全く別物の仕組みやステータスを保有しているのを、共通化・抽象化するという業務が必要になる`}</strong>{`ので、すごく難しいし、難易度が高い部分だなと思っているんですけど、それを綺麗にシーケンス図に落とし込んだ有馬さんが凄いなと思いました。`}</p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 基盤側で作っていただいたシーケンス図は UX の骨組みなんですよね。そこをいわゆる UX デザイナーが別途やるといった細分化をせず、実装の制限を加味しつつ、正しく運用を回すために、この体験を作るためにこのステータス遷移であるべきだよね、という部分を有馬さんがエンジニア観点で最初に作ってくれてるというのが、`}<strong parentName="p">{`メドレーのエンジニアのとても良い部分`}</strong>{`だと思っています。技術だけでなく UX などにも必要であれば越境していける文化ですね。`}</p>
    <h1>{`同時予約 Pj 開発の実際`}</h1>
    <p><strong parentName="p">{`── そうした初期フェーズを経て Pharms と CLINICS どちらの開発にも関わった小田さんに聞きたいのが、開発の流れの中でいわゆるリソース的な問題って大変だったと思うのですが、そこはどう解決したんでしょうか。`}</strong></p>
    <p><strong parentName="p">{`小田`}</strong>{`: 先に Pharms 側の機能 を作ってその後に CLINICS の仕様を決めてもらってから CLINICS の開発に入る流れだったので開発が並行することはなかったです。ただ Pharms ではリードエンジニアという立場なので 、CLINICS の開発に入るための準備と、Pharms 側のレビューやフォローのリソース配分は自分で考えて、各タスクを実施する時間とタイミングを調整しながらやっていました。`}</p>
    <p>{`一方で、この体制でも進めることができたのは `}<strong parentName="p">{`Pharms チームの各メンバーが既に自走できる状態にあったので、そこに支えられたからこそできた`}</strong>{`かなと思ってます。`}</p>
    <p><strong parentName="p">{`── プロダクトも越境しながら開発するという文化はすごく良いと思ったのですが、ここはフローなどあったりしたんですか?`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 先程も話した通り Pharms として本プロジェクトの優先度は高かったのですが、CLINICS の事業計画やリソースを踏まえると医科側の実装着手が大きく遅れそうな見立てでした。そこで、小田さんを 医科側の開発にもアサインできたらリソース問題が解決できるのではと調整しました。医科側のエンジニアからのフォローアップは必要なので、その調整もしつつですが、開発を早められるなら良し、と開発チーム間で最終的に判断しました。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{`: 今まで触ってなかった CLINICS のシステムにも小田さんはガンガン切り込んでくださったので、元々 CLINICS の開発をやってたのではと思うくらいキャッチアップが早かったです。`}</p>
    <p><strong parentName="p">{`── プロダクトのグロースのためにリソース調整を成功させたという形だったんですね。プロダクト間の越境がしやすいような文化だったり、プロジェクトを成功させようという目線合わせが自然にできるのは良いですね。`}</strong></p>
    <p>{`チーム間という話でいえば QA は各チームに跨っての活動だと思いますが、その観点ではいかがですか?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/263872020b5385451a63a8e05c7d79d4/eea4a/_dev202403_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFBAb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFuqUkrHOh//8QAHBAAAQQDAQAAAAAAAAAAAAAAAgABAyIREhMx/9oACAEBAAEFAglttgehI2vJ66//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERAhASIYH/2gAIAQEABj8C4t1lcOlUYnmv/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFBYf/aAAgBAQABPyGr4DgpiGYvx5mj+BEwCAWm4k8z1v/aAAwDAQACAAMAAAAQk+//xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQMBAT8QrH//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/ENKl/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBYXGx8P/aAAgBAQABPxALAKUKxePHqOk3yLR4uKZkbTQ639cKK2QzMDAjYacwF5//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " dev202403 002",
            "title": " dev202403 002",
            "src": "/static/263872020b5385451a63a8e05c7d79d4/e5166/_dev202403_002.jpg",
            "srcSet": ["/static/263872020b5385451a63a8e05c7d79d4/f93b5/_dev202403_002.jpg 300w", "/static/263872020b5385451a63a8e05c7d79d4/b4294/_dev202403_002.jpg 600w", "/static/263872020b5385451a63a8e05c7d79d4/e5166/_dev202403_002.jpg 1200w", "/static/263872020b5385451a63a8e05c7d79d4/eea4a/_dev202403_002.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`小島`}</strong>{`: 自分は開発が終盤に入ったタイミングでプロジェクトに合流しました。それまでは米山さんが QA として入っていたのですが、医科側でも検証すべきテスト観点が多数あった背景もあり、医科側の QA をメインで見る担当として参画しました。`}</p>
    <p>{`後半に参画した背景もあり、そもそも処方箋送付という業務や Pharms プロダクトの仕様理解も浅い…という状態でしたが、`}<strong parentName="p">{`開発目的・要件定義・設計が詳細にドキュメント化されていたので、スムーズに検証に入ることができました`}</strong>{`。検証の中で QA エンジニアとしても「この部分の仕様は見直した方が良くなる」というポイントがいくつか出てきたので、仕様の改善提案も行いました。後発参加でしたが、実際に取り入れてもらった改善も多いです。`}</p>
    <p>{`メドレーのプロダクト開発の文化として QA エンジニアも`}<strong parentName="p">{`要件定義からレビューに参加したり、意見を言いつつプロダクトをより良くするための改善が行える`}</strong>{`ので、とても働きやすいですね。`}</p>
    <p><strong parentName="p">{`── 後から入っても動きやすいというのは、ドキュメントなどが整備されているからできたことだというのが分かりますね。横断プロジェクトだからこそ気がついた他のチームの良い点などあったりしますか?`}</strong></p>
    <p><strong parentName="p">{`酒井`}</strong>{`: Pharms のプロジェクトってやりやすいな、と思ったのが KPI 設計がすごく明確なところだったという点でした。`}</p>
    <p>{`色々紆余曲折あった結果ここに辿り着いてるという苦労を知ってはいるんですけど、今の `}<strong parentName="p">{`KPI 設計ってやっぱりすごくシンプル`}</strong>{`で処方箋送信数ですとなると、そこから逆算した KPI を各 PF に振ればいいと言う体制だったので、すごくこれはやりやすかったポイントの一つかなと思います。`}</p>
    <p>{`話す論点も結局そこっていう部分がすごくコミュニケーションコストが安くすんだ部分ではあるかなと思いました。`}</p>
    <h1>{`横断プロジェクトならではのエンジニアリング`}</h1>
    <p><strong parentName="p">{`── 全体の話から個別のドメインごとのエンジニアリングという点ではどのようなことをされていましたか?`}</strong></p>
    <p><strong parentName="p">{`小田`}</strong>{`: 基本的には有馬さんのシーケンス図を元に開発を進めていました。CLINICS では A という状態になる、その時に B というアクションがあると Pharms では C という状態になるというように状態遷移が複雑だったため、設計時にあるべき姿に迷うことが多かったのですが、シーケンスに立ち返るとすぐにその疑問が解決するといった具合です。`}</p>
    <p><strong parentName="p">{`有馬`}</strong>{`: `}<strong parentName="p">{`シーケンスの整合性や難しくなりすぎないように設計する`}</strong>{`ことには気をつけました。「同時予約」という言葉が先行するとついつい複雑な新機能のようなものを作りがちですが、診療所や薬局の予約機能、処方箋送信機能といった個々のパーツはもともとあるわけで、なるべくシンプルにそれらをつなぎ合わせられるように心がけました。`}</p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 細かいプロダクト間の調整が必要になるので ちゃんと調剤の欲しい KPI も求めつつ、かつ現場のオペレーション品質を落とさないっていう観点で調整を各ドメインでやりました。`}</p>
    <p>{`工夫したことでいうと、UX 調査を徹底したところでしょうか。`}</p>
    <p>{`今回のサービスって業界的にみた時に新しいかというとそうではないんですよね。ですので、同業界の企業さんの各アプリの体験とか、自分でも結構色々使いながら体験してみましたし、それらをベースに逆に現状のうちの仕様を踏まえると、こういう風にうちでは実装しましょうみたいなところを色々やっていました。`}</p>
    <p><strong parentName="p">{`小田`}</strong>{`: エンジニアリングの観点でいうと、特に患者アプリチームとの API 連携の仕様調整と、開発の進め方は密に連携しながら進めました。先にこっちができてないと向こうも開発できない状態になるので、担当者同士でコミュニケーションとりつつ、工夫しながら進めていました。`}</p>
    <p><strong parentName="p">{`酒井`}</strong>{`: CLINICS で気にしてた部分はどちらかというと`}<strong parentName="p">{`リスクとコストの部分、いかに最小化して価値を最大化するかが一番重要`}</strong>{`かなと思っていたので、一番大事なのはやっぱり医科にとってどういう価値が出て、医科にとってどういうリスクが発生するかを考えてました。`}</p>
    <p>{`例えば一番最初のすり合わせで薬局予約する時に日時まで確定させましょうという話もあったんですけど、そこまでスコープを広げなくてもメリットが出るのであれば、広げたくないですという話をしていました。`}</p>
    <p>{`あとはリリースプランの策定とかかな？全医療機関に一斉に出すのではなく、まずは一部医療機関様だけに公開するリリース方式にしましょうみたいなところも含めてですかね。医科側で一旦テストをやって検証して、スムーズに機能が使えるところを確認したりするステップを設けましょうと安全策を取りながら進めました。`}</p>
    <p>{`これはこのプロジェクトに関わらず、どんなプロジェクトでも一番重要でそこが後からひっくり返るのが一番手戻りになってくるので、そこは重要かなと思いながら進めてましたね。`}</p>
    <p><strong parentName="p">{`小島`}</strong>{`: QA 視点だと横断プロジェクトゆえにステートが本当に複雑で、メドレー入社後に経験したプロジェクトの中で検証の難易度が最も高かったですね。`}</p>
    <p>{`ですが、皆さん仰られているように各ユースケースに対する設計が図でドキュメント化されていたので、テストケースとして転用できた部分も多くありました。有馬さんが作成した設計図があったからこそ漏れなくテストできた部分は大きいです。大勢が参加するプロジェクトにおいて、全員が認識合わせる上でも設計図は本当に大事だなと痛感しました。`}</p>
    <p>{`工夫した点としては、小田さんは Pharms 開発のドメイン知識が豊富だったので、QA 時は Pharms 側をメインに見てもらいつつ、自分は CLINICS 側の QA を手厚く見るようにしました。どちらのドメインにも関係する契約プラン等の複雑な仕様は自分が担当しました。`}</p>
    <p>{`QA 以外の面だと、リリースする上で必要な浮いたボールがあれば積極的に取りにいくようにしました。`}</p>
    <p>{`例えば、これまで一部の医療機関様だけに機能リリースする場合、その後のフィードバック回収はメールや面談で個別に行っていましたが、日々の診療の中でお時間を確保頂くのが難しいという状況がありました。その結果、本 Pj においても全体リリースして問題ないか？の判断がしづらい時期がありました。`}</p>
    <p>{`そこで Google フォームでアンケートを作成して短時間で回答できる形に切り替えました。結果的に 100% 回答を得ることができました。`}</p>
    <p>{`アンケート結果から「従来機能より価値があり、院内オペレーションに組み込んで頂くコストもそこまで高くない」という点が評価できたので、自信を持って全体公開リリースに向けて GO 判定できました。`}<strong parentName="p">{` メドレーは役割に縛られずボールを任せて頂ける環境があるので、自分の専門領域以外のことも学べている`}</strong>{`と思うことが多いです。`}</p>
    <p>{`幸いリリース後も大きな不具合はないのでこれはもう皆さんのおかげですね。`}</p>
    <h1>{`同時予約 Pj を終えて`}</h1>
    <p><strong parentName="p">{`── では最後にこのプロジェクトの総括をしていだければ。`}</strong></p>
    <p><strong parentName="p">{`江藤`}</strong>{`: 横断ではあるけど各ドメインでそれぞれのプロフェッショナルがちゃんと力を発揮してプロジェクトをやり切れたというのはすごく大きい資産でした。`}</p>
    <p><strong parentName="p">{`医療 PF はアセットをたくさん持っているので、色々組み合わせると、他の会社だとできないけど、うちならできそうだね、みたいなことが色々ある`}</strong>{`んです。`}</p>
    <p>{`なので、この後も横断プロジェクトは予定されていますが、それに先駆けてひとつやりきって、かつ僕らが欲しかった期待成果を現状出せているところを含めるとすごく良い一歩だったのかな、と思ってます。`}</p>
    <p>{`次は品質を担保しつつ、もっとスピードを上げていきたいというのが観点として大きいですね。`}</p>
    <p><strong parentName="p">{`── なるほど。こうした横断プロジェクトもやっていく医療 PF ではどんなエンジニアがマッチするポイントでしょう?`}</strong></p>
    <p><strong parentName="p">{`有馬`}</strong>{`: 技術と併せてユーザーへの価値提供も考えながらエンジニアリングしたい人がマッチするのではないかと思います。`}</p>
    <p><strong parentName="p">{`── 各プロダクトの持っている役割を踏まえて、俯瞰の視点で開発もしたいという方にはすごく魅力的ですね。本日はありがとうございました。`}</strong></p>
    <h1>{`さいごに`}</h1>
    <p>{`同時予約 Pj について、プロジェクトを推進したメンバーに話を聞きましたが、複雑な要件を実現可能な形にして各チームで垣根を越えて開発をしている様子がとても印象的でした。特に医療 PF ではドメイン同士で有機的に結合していき、コラボレーションすることによりステークホルダーへの価値を高めているというのは、やりがいがあるのではないかと感じます。`}</p>
    <p>{`このようなプロジェクトで力を発揮したいと思った方はぜひ、お気軽にお話をしましょう!`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      